import { Chapter } from '@models/chapter';
import { ContentItem } from '@models/content-item';

export class MenuItem {
  chapterUuid?: string;
  content: ContentItem[];
  icon: string;
  label: string;
  page: string;
  url?: string;

  constructor(chapter: Chapter) {
    this.chapterUuid = chapter.uuid;
    this.content = chapter.contentItems.map(item => new ContentItem(item));
    this.label = `menuitem.${chapter.name}`;
    this.icon = chapter.name;
    this.page = chapter.name;
  }
}
